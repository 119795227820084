import { useApi } from '@/api';
import { ChartSkeleton, ChartTimeFilter } from '@/components';
import { Flex } from '@blockanalitica/ui';
import { useMemo, useState } from 'react';
import HistoricGraphs from './HistoricGraphs';

export default function HistoricTabs({ ilk }: { ilk: string }) {
  const [chartDaysAgo, setChartDaysAgo] = useState('30');

  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/snapshots/`,
    queryParams: {
      days_ago: chartDaysAgo
    }
  });

  if (error) {
    throw error;
  }

  const timeFilter = useMemo(
    () => (
      <ChartTimeFilter activeOption={chartDaysAgo} onChange={setChartDaysAgo} />
    ),
    [chartDaysAgo]
  );

  if (data) {
    return (
      <Flex flexDirection="column" gap="xs">
        <HistoricGraphs data={data} type={'volume'} timeFilter={timeFilter} />
      </Flex>
    );
  }

  return <ChartSkeleton />;
}
