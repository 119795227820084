import Logo from '@/assets/logos/BA-logo-full-white.svg?react';
import { ExternalLink } from '@/components';
import {
  CryptoIcon,
  Flex,
  Heading,
  WalletAddress,
  Box
} from '@blockanalitica/ui';
import { ReactNode } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { styled } from 'styled-components';

const Wrapper = styled(Flex)`
  box-sizing: border-box;
`;

const ContentWrapper = styled(Flex)`
  box-sizing: border-box;
  padding: ${(props) =>
    `${props.theme.space['m-l']} ${props.theme.space['s-m']}`};
`;

type FixedSidebarLayoutProps = {
  children?: ReactNode;
};

export default function FixedSidebarLayout({
  children
}: FixedSidebarLayoutProps) {
  return (
    <Wrapper height="100%" flexWrap="nowrap" width="100%" gap="0">
      <ContentWrapper
        flexGrow={1}
        flexShrink={1}
        flexBasis={['100%', 'auto']}
        margin={['0 auto']}
        maxWidth={['100vw', '75rem']}
        flexDirection="column"
        justifyContent="space-between"
        gap="0">
        <Flex
          gap="3xs"
          justifyContent="space-between"
          style={{ marginBottom: '1rem' }}>
          <Flex gap="2xs" color="white">
            <Link to="/">
              <Flex gap="2xs" color="white" flexDirection="row">
                <CryptoIcon name="MKR" size="4" />
                <Heading size="1">LitePSM</Heading>
              </Flex>
            </Link>
            <Flex color="muted" size="1">
              <WalletAddress
                address="0xf6e72Db5454dd049d0788e411b06CfAF16853042"
                options={{
                  inline: true,
                  showEthScanLink: true,
                  size: 'small'
                }}
              />
            </Flex>
          </Flex>
          <ExternalLink href="https://blockanalitica.com">
            <Logo height={35} />
          </ExternalLink>
        </Flex>
        <main>{children ? children : <Outlet />}</main>
        <footer>
          <Flex fontSize="-2" justifyContent="flex-end" paddingTop="xs">
            <ExternalLink href="https://blockanalitica.com/terms-of-service/">
              <Box color="muted">Terms of Service</Box>
            </ExternalLink>
          </Flex>
        </footer>
      </ContentWrapper>
    </Wrapper>
  );
}
