import { Skeleton } from '@/components';
import { Table } from '@blockanalitica/ui';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';
import { useMemo } from 'react';

export type TableSkeletonProps = {
  rows?: number;
  columns?: number;
  hasDoubleCell?: boolean;
};

export default function TableSkeleton({
  rows = 3,
  columns = 4,
  hasDoubleCell = false
}: TableSkeletonProps) {
  const tableRows = useMemo(
    () =>
      Array.from(Array(rows), (_, index) => ({
        index
      })),
    [rows]
  );

  const tableColumns: TableColumn[] = useMemo(
    () =>
      Array.from(Array(columns), () => {
        const col = {
          header: <Skeleton height="2" />,
          getCell: () => <Skeleton width="100%" height="2" />
        };

        if (hasDoubleCell) {
          col['getCellBottom'] = () => null;
        }
        return col;
      }),
    [columns, hasDoubleCell]
  );

  return (
    <Table
      rows={tableRows}
      columns={tableColumns}
      getKey={(row) => row.index}
    />
  );
}
