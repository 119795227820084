import { useApi } from '@/api';
import { TableSkeleton } from '@/components';

import {
  CryptoIcon,
  CurrencyValue,
  DataTable,
  EtherScanPictogram,
  Flex,
  Heading,
  RelativeDateTime,
  WalletAddress,
  useTableProps
} from '@blockanalitica/ui';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';

export default function GemEventsTable({ ilk, walletAddress }) {
  const { pagination, sorting } = useTableProps({
    pageSize: 10,
    initialSorting: '-order_index',
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/gems/wallets/${walletAddress}/events/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns: TableColumn[] = [
      {
        header: 'Wallet Address',
        getCell: (row) => (
          <WalletAddress
            address={row.from_wallet_address}
            options={{ size: 'xSmall', name: row.frop_wallet_name }}
          />
        ),
        cellAlign: 'flex-start',
        headerAlign: 'flex-start',
        columnSize: ['minmax(100px, auto)']
      },
      {
        header: 'Event',
        getCell: (row) => row.event,
        cellAlign: 'flex-start',
        headerAlign: 'flex-start',
        columnSize: ['minmax(100px, auto)']
      },
      {
        header: (
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="3xs">
            Amount
            <CryptoIcon name="DAI" size="-1" />
          </Flex>
        ),
        getCell: (row) => (
          <CurrencyValue
            value={row.dai_amount}
            options={{ trend: true, iconSize: '-1' }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'dai_amount'
      },

      {
        header: (
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="3xs">
            Amount
            <CryptoIcon name="USDC" size="0" />
          </Flex>
        ),
        getCell: (row) => (
          <CurrencyValue
            value={row.usdc_amount}
            options={{ trend: true, iconSize: '-1' }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'usdc_amount'
      },
      {
        header: 'Time',
        getCell: (row) => (
          <Flex gap={['xs', '3xs']} color="muted">
            <RelativeDateTime value={row.datetime} />
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size="0" />
            </a>
          </Flex>
        ),

        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <>
        <Heading size="3">Events</Heading>
        <DataTable
          rows={data['results']}
          columns={columns}
          getKey={(row) => `${row.order_index}`}
          sorting={sorting}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
        />
      </>
    );
  }
  return <TableSkeleton />;
}
