import { extendTheme } from '@blockanalitica/ui';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { colors } from './colors';
import { chartKpi } from './components/ChartKpis/chartKpi';
import { chartKpiLabel } from './components/ChartKpis/chartKpiLabel';
import { chartKpiPoint } from './components/ChartKpis/chartKpiPoint';
import { chartKpiSubValue } from './components/ChartKpis/chartKpiSubValue';
import { chartKpiValue } from './components/ChartKpis/chartKpiValue';
import { card } from './components/card';
import { cardHeader } from './components/cardHeader';
import { cardListHeader } from './components/cardListHeader';
import { chart } from './components/chart';
import { collapsableSidebar } from './components/collapsableSidebar';
import { heatmap } from './components/heatmap';
import { heatmapCell } from './components/heatmapCell';
import { heatmapColumnLabel } from './components/heatmapColumnLabel';
import { heatmapRowLabel } from './components/heatmapRowLabel';
import { kpiCardValue } from './components/kpiCardValue';
import { kpiFooter } from './components/kpiFooter';
import { kpiHeader } from './components/kpiHeader';
import { linkButton } from './components/linkButton';
import { listHeader } from './components/listHeader';
import { listItem } from './components/listItem';
import { select } from './components/select';
import { sidebar } from './components/sidebar';
import sizes from './sizes';
import space from './space';

export const theme: Theme = extendTheme({
  rootFontSize: '12px',
  colors,
  sizes,
  space,
  components: [
    select,
    card,
    cardHeader,
    cardListHeader,
    kpiHeader,
    kpiFooter,
    kpiCardValue,
    linkButton,
    listHeader,
    listItem,
    heatmap,
    heatmapColumnLabel,
    heatmapRowLabel,
    heatmapCell,
    chartKpi,
    chartKpiPoint,
    chartKpiLabel,
    chartKpiValue,
    chartKpiSubValue,
    chart,
    sidebar,
    collapsableSidebar
  ]
});
