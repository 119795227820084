import { useApi } from '@/api';
import { KpiSkeleton } from '@/components';
import { tokensMapping } from '@/utils/tokens';
import { Flex, KpiCard } from '@blockanalitica/ui';

export default function TopKpis({ daysAgo }) {
  const [data, error] = useApi({
    url: '/groups/stablecoins/ilks/LITE-PSM-USDC-A/parameters/',
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Flex flexDirection="column" gap="m">
        <Flex flexDirection={['column', 'row']} gap={['m', 's']}>
          <KpiCard
            title="USDC in PSM"
            data={data}
            field="reserve"
            options={{
              valueOptions: {
                currencyValueOptions: {
                  currency: tokensMapping('USDC'),
                  iconSize: '3'
                }
              },
              variants: {
                kpiCardValue: 'large'
              }
            }}
          />
          <KpiCard
            title="DAI in PSM"
            data={data}
            field="dai"
            options={{
              valueOptions: {
                currencyValueOptions: {
                  currency: tokensMapping('DAI'),
                  iconSize: '3'
                }
              },
              variants: {
                kpiCardValue: 'large'
              }
            }}
          />
        </Flex>
      </Flex>
    );
  }

  return <KpiSkeleton />;
}
