import { Flex, FlexProps, resolveThemeVariable } from '@blockanalitica/ui';
import type { Size } from '@blockanalitica/ui/package/types/src/theme/types';
import ReactLoadingSkeleton, {
  SkeletonProps as LoadingSkeletonProps
} from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled, { css, useTheme } from 'styled-components';

const Container = styled(Flex)<FlexProps>`
  & .skeleton-container {
    flex: 1;
  }
`;

const Wrapper = styled(ReactLoadingSkeleton)`
  &.react-loading-skeleton {
    ${(props) => css`
      --base-color: ${props.theme.colors.skeletonBaseColor};
      --highlight-color: ${props.theme.colors.skeletonHighlightColor};
      height: ${props.height};
      width: ${props.width};
      border-radius: ${props.theme.sizes['-2']};
    `}
  }
`;

export interface SkeletonProps extends LoadingSkeletonProps {
  width?: Size | string | number;
  height?: Size | string | number;
}

export default function Skeleton({ width, height, ...rest }: SkeletonProps) {
  const theme = useTheme();

  return (
    <Container
      flexBasis="100%"
      alignItems="center"
      justifyContent="center"
      width={
        typeof width === 'number'
          ? width + 'px'
          : resolveThemeVariable<Size | string>(width, theme.sizes)
      }
      {...rest}>
      <Wrapper
        containerClassName="skeleton-container"
        height={
          typeof height === 'number'
            ? height + 'px'
            : resolveThemeVariable<Size | string>(height, theme.sizes)
        }
        width={
          typeof width === 'number'
            ? width + 'px'
            : resolveThemeVariable<Size | string>(width, theme.sizes)
        }
      />
    </Container>
  );
}
