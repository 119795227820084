import { Skeleton } from '@/components';
import { Flex, FlexProps } from '@blockanalitica/ui';

interface ChartSkeletonProps extends FlexProps {
  type?: 'line' | 'doughnut';
}

export default function ChartSkeleton({
  type = 'line',
  ...rest
}: ChartSkeletonProps) {
  switch (type) {
    case 'doughnut':
      return (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap="xs"
          {...rest}>
          <Flex justifyContent="center" alignItems="center" flexBasis="80%">
            <Skeleton width={200} height="10" />
          </Flex>

          <Flex
            flexDirection="column"
            alignItems="center"
            gap="3xs"
            flexBasis="20%">
            <Skeleton width="9" height="1" />
            <Skeleton width="9" height="1" />
            <Skeleton width="9" height="1" />
          </Flex>
        </Flex>
      );
    default:
      return (
        <Flex flexDirection="column" gap="2xs" {...rest}>
          <Skeleton width={250} height="5" />
          <Skeleton width={150} height="5" />
          <Skeleton width="100%" height={230} />
        </Flex>
      );
  }
}
