import { Skeleton } from '@/components';
import { Card, CardProps } from '@blockanalitica/ui';

export default function KpiSkeleton(props: CardProps) {
  return (
    <Card gap="xs" {...props}>
      <Skeleton height="1" />
      <Skeleton height="4" />
    </Card>
  );
}
